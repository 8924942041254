<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1 uk-grid-small">
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3 uk-margin-remove">
              <span data-uk-icon="pencil"></span> {{ headerLabel }}
            </h3>
          </div>
          <div>
            <router-link :to="{name: 'tenant'}" class="uk-margin-right uk-button uk-button-text" v-if="!submitting">
              abbrechen
            </router-link>
            <div class="uk-button-group">
              <button
                class="uk-button uk-button-primary"
                @click.prevent="store"
                :disabled="submitting"
              >
                <div v-if="submitting" data-uk-spinner="ratio: 0.7" class="uk-margin-small-right"></div>
                {{ buttonLabel }}
              </button>
              <div class="uk-inline" v-if="tenantId">
                <button class="uk-button uk-button-primary" type="button" :disabled="submitting">
                  <span uk-icon="icon: triangle-down"></span>
                </button>
                <div
                  ref="storeDropdown"
                  uk-dropdown="animation: uk-animation-slide-top-small; duration: 200;delay-hide: 0;mode: click; boundary: ! .uk-button-group; boundary-align: true;pos: bottom-right"
                >
                  <ul class="uk-nav uk-dropdown-nav">
                    <li>
                      <a href="#" @click.prevent="storeAndClose">Speichern und Schließen</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <profile v-model="form" :users="users" :is-admin="true" />
    <modal ref="customerCreatedModal">
      <div class="uk-modal-header">
        <h3>Kunde {{ form.company }} wurde angelegt!</h3>
      </div>
      <div class="uk-modal-body">
        <div class="uk-margin">
          <label>Passwort:</label>
          <div class="uk-inline uk-width-1-1">
            <a class="uk-link-reset uk-form-icon uk-form-icon-flip" @click.prevent="doCopy" style="width: 50px;" data-uk-tooltip="In die Zwischenablage kopieren">
            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="uk-icon uk-svg" data-svg="../images/icon-clipboard.svg">
              <line fill="none" stroke="#000" x1="5.5" y1="9.5" x2="15" y2="9.5"></line>
              <line fill="none" stroke="#000" x1="5" y1="1.5" x2="9" y2="1.5"></line>
              <polyline fill="none" stroke="#000" stroke-width="1.03" points="8.5,6.5 5.5,9.5 8.5,12.5"></polyline>
              <polyline fill="none" stroke="#000" points="12.5,7 12.5,2.5 9.5,2.5 9.5,3.5 4.5,3.5 4.5,2.5 1.5,2.5 1.5,15.5 12.5,15.5 12.5,12"></polyline>
            </svg>
          </a>
          <input class="uk-input" type="text" v-model="password" disabled>
          </div>
        </div>
        <div class="uk-margin">
          <p>
            <strong>Admin: </strong> <a href="http://eln-admin.descpro.de/login" target="_blank">http://eln-admin.descpro.de</a><br>
            <strong>Homepage: </strong> <a :href="url" target="_blank">{{ url }}</a>
          </p>
        </div>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <button class="uk-button uk-button-link uk-modal-close uk-margin-small-right" type="button">Schließen</button>
      </div>
    </modal>
  </div>
</template>

<script>
import TenantService from '@/services/tenant.service'
import PageService from '@/services/page.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import Modal from '@/components/Modal'
import axios from 'axios'
import Profile from '@/components/Profile'
export default {
  data () {
    return {
      submitting: false,
      error: false,
      tenantId: this.$route.params.id,
      users: [],
      closeAfterSave: false,
      password: '',
      url: '',
      form: {
        company: '',
        firstname: '',
        lastname: '',
        zipcode: '',
        city: '',
        phone: '',
        email: '',
        package: 'Bronze',
        theme: 'blue'
      }
    }
  },
  mounted () {
    if (this.tenantId) {
      this.getTenant()
    }
  },
  components: {
    Modal,
    Profile
  },
  computed: {
    headerLabel () {
      return this.tenantId ? 'Kunde bearbeiten' : 'Kunde erstellen'
    },
    buttonLabel () {
      let label = 'Speichern'
      if (!this.tenantId && !this.submitting) {
        label = 'Kunde anlegen'
      }
      if (!this.tenantId && this.submitting) {
        label = 'Kunde wird angelegt. Bitte warten...'
      }

      return label
    }
  },
  methods: {
    async getTenant () {
      const tenant = await TenantService.get(this.tenantId)

      this.form = tenant.data.profile
      this.users = tenant.data.users
    },
    async store () {
      this.submitting = true
      let method = null
      let response = null

      try {
        if (this.tenantId) {
          method = 'update'
          response = await TenantService.update(this.tenantId, this.form)

          iziToast.success({
            title: 'OK',
            message: 'Kunde gespeichert!',
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft'
          })
        } else {
          method = 'store'
          response = await TenantService.store(this.form)
          const tenantId = response.data.tenant.id

          const tokenResponse = await TenantService.getTokenForTenant(tenantId)

          // const containerResponse = await PageService.createContainer({ token: tokenResponse.data.token })
          const dbName = response.data.tenant.name.replace(/-/g, '_')
          try {
            await axios.get('http://create-app.eln.ansolution.de', {
              params: {
                app: `eln${tenantId}`,
                port: 4000 + parseInt(tenantId),
                token: tokenResponse.data.token
              }
            })
          } catch (error) {
            return
          }
          this.password = response.data.password
          this.url = `http://eln${tenantId}.eln.ansolution.de`

          await PageService.createDb({ token: tokenResponse.data.token, dbName: dbName })

          this.tenantId = tenantId
          this.getTenant()
          this.$refs.customerCreatedModal.open()
        }

        this.submitting = false

        if (this.closeAfterSave) {
          this.$router.replace({ name: 'tenant' })
          return
        }

        if (method === 'store') {
          this.$router.replace({ name: 'tenant-edit', params: { id: response.data.tenant.id } })
        }
      } catch (error) {
        // console.log(error)
        this.error = true
        this.submitting = false
        iziToast.error({
          title: 'Fehler',
          message: 'Es ist ein Fehler aufgetreten!',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })
      }
    },
    storeAndClose () {
      this.closeAfterSave = true
      this.store()
    },

    doCopy: function () {
      this.$copyText(this.password).then(e => {
        iziToast.info({
          title: 'Passwort kopiert!',
          message: '',
          timeout: 3000,
          position: 'topCenter',
          transitionIn: 'fadeInUp'
        })
      }, e => {
        // console.log(e)
      })
    }
  }
}
</script>
